$neutral-background: #F6FAF2;
$light-background: #F6FBF1;
$accent-fill-rest: #87C544;
$neutral-foreground-rest: #262626;
$neutral-gray: #eee;

.logo-right {
    max-width: 90px;
    max-height: 80px;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

.logo-left {
    max-width: 90px;
    max-height: 80px;
    margin-left: 0rem;
    margin-bottom: 1rem;
}

.float-right {
    float: right;
}

.bolder{
    font-weight: bolder;
}

.curricula_page {
    margin-bottom: 5rem;
}

.teaching_theme{
    padding: 20px;
    margin: 10px;
    border-radius: 1px;
    box-sizing: border-box;
    flex-basis: 383px;
    //flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}   

@media (max-width: 1268px){
    .teaching_theme{
        flex-grow: 1;
        flex-basis: 550px;
    }
}

.curricula_grid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.teaching_theme_title {
    margin-left: 20px;
    display: inline;
}

.teaching_theme_read_more{
    margin: 5px;
    position: flex;
    bottom: 0;
}

.teaching_theme_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
}

.teaching_theme_header:hover {
    color: black;
}

.teaching_theme_header:visited {
    color: black;
}

.teaching_theme_container {
    display: flex;
    width: 100%;
    justify-content: left;
}

.teaching_theme_teaser {
    text-align: justify;
    text-justify: inter-word;
}

.curricula_select_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    border-radius: 1px;
    margin-left:1.5rem;
    margin-right: 1.5rem;
}

@media (min-width: 672px) {
    .curricula_select_container select:first-of-type{
        margin-right: 2rem;
    }
    .curricula_select_container select:last-of-type{
        margin-left: 2rem;
    }
}

@media (max-width: 673px){ 
    .curricula_select_container {
        flex-basis: 100%;
    }
    .curricula_select_container select {
        min-width: 100%;
        margin: 0;
    }
}


.curricula_select_container select {
    margin-top:1rem;
    -webkit-appearance: button;
    -moz-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-padding-end: 20px;
    -moz-padding-end: 20px;
    -webkit-padding-start: 2px;
    -moz-padding-start: 2px;
    background-color: $neutral-background; /* Fallback color if gradients are not supported */
    background-position: center right;
    background-repeat: no-repeat;
    border: 1px solid $accent-fill-rest;
    border-radius: 2px;
    color: #555;
    font-size: inherit;
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    min-width: 250px;
    max-width: 33rem;
    appearance: none;
    flex-grow: 1;
    flex-basis: 250px;
}


.project_highlights_title{
    z-index: 10;
}